


















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  data() {
    return {
      drawer: null as boolean | null,
      title: "Giriş",
      course: {
        id: "",
        price: 0,
        name: "",
        color: "",
        code: "",
        previewImg: "",
        urlCode: "",
        learningOutcomes: [],
        modules: [],
        desc: {
          short: "",
          long: ""
        },
        rating: {
          value: 0,
          count: 0
        }
      },
      progress: 15,
      currentMenuItem: {} as any,
      courseRegistration: {} as any,
      dialogCert: false,
      certUrl: ""
    };
  },
  computed: {
    user(): any {
      return this.$store.getters["auth/user"];
    },
    menuItems(): any[] {
      return [
        {
          icon: "home",
          title: "Giriş",
          link: { name: "course-home" },
          exact: true
        },
        {
          icon: "play_circle_filled",
          title: "Konular",
          link: { name: "course-lessons" },
          exact: false
        },
        {
          icon: "chat",
          title: "Tartışmalar",
          link: { name: "course-discussions" },
          exact: false
        }
        // {
        //   icon: "alarm_on",
        //   title: "Ödevler",
        //   link: "/course/" + this.course.urlCode + "/assignments",
        //   exact: false
        // },
        // {
        //   icon: "list_alt",
        //   title: "Sınavlar",
        //   link: "/course/" + this.course.urlCode + "/exams",
        //   exact: false
        // },
        // {
        //   icon: "video_library",
        //   title: "Seminerler",
        //   link: "/course/" + this.course.urlCode + "/webinars",
        //   exact: true
        // }
      ];
    },
    business() {
      return this.$store.getters["auth/business"];
    }
  },
  methods: {
    goTo(item: any) {
      this.currentMenuItem = item;
      this.title = item.title;
    },
    signOut() {
      this.$store.dispatch("auth/signOut");
    },
    getCourseRegistration() {
      firebase
        .firestore()
        .collection("businessCourseRegistrations")
        .where("uid", "==", this.user.id)
        .where("courseId", "==", this.course.id)
        .get()
        .then(async regQs => {
          if (!regQs.empty) {
            const reg = regQs.docs[0];
            const newReg = reg.data();
            newReg.id = reg.id;

            if (!newReg.completedLessons) {
              newReg.completedLessons = [];
            }

            if (!newReg.completedLessonItems) {
              newReg.completedLessonItems = [];
            }

            this.courseRegistration = newReg;
          }
        });
    },
    async getCertUrl() {
      return await firebase
        .storage()
        .ref(`certificates/${this.courseRegistration.id}.pdf`)
        .getDownloadURL();
    },
    async getCert() {
      // Dersi tamamlamamışsa kullanıcıyı bilgilendir
      if (this.courseRegistration.progress < 100) {
        this.$notify({
          title: "Eğitimi tamamlamadınız!",
          text: `Katılım sertifikanızı alabilmek için tüm
          modülleri tamamlamanız gerekmektedir.`
        });
        return;
      }

      // Sertifika adresi alınmış mı kontrol et
      if (this.certUrl) {
        this.dialogCert = true;
        return;
      }

      try {
        this.certUrl = await this.getCertUrl();
        this.dialogCert = true;
      } catch {
        this.$notify({
          title: "Lütfen bekleyin!",
          text: "Sertifikanız oluşturuluyor...",
          duration: -1
        });

        // Sertifikayı oluştur
        const createCertificate = firebase
          .functions()
          .httpsCallable("certificate-createBusinessCertificate");

        await createCertificate({
          cregId: this.courseRegistration.id
        });
      }

      // Her 1 sn'de sertfikayı kontrol et.
      const timer = setInterval(async () => {
        try {
          this.certUrl = await this.getCertUrl();

          if (this.certUrl) {
            this.$notify({ clean: true });
            this.dialogCert = true;

            clearInterval(timer);
          }
        } catch {
          console.error("Sertifika bulunamadı");
        }
      }, 1000);
    }
  },
  mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;

    this.$store.dispatch("auth/changeTheme", "business");

    /* eslint-disable */
    // @ts-ignore
    Tawk_API.hideWidget();
    /* eslint-enable */

    // Ders bilgilerini al
    firebase
      .firestore()
      .collection("courses")
      .where("urlCode", "==", this.$route.params.courseUrlCode)
      .limit(1)
      .get()
      .then(qs => {
        const course = qs.docs[0];
        if (course.exists) {
          this.course.id = course.id;
          this.course.price = course.data().price;
          this.course.name = course.data().name;
          this.course.color = course.data().color;
          this.course.code = course.data().code;
          this.course.previewImg = course.data().previewImg;
          this.course.urlCode = course.data().urlCode;
          this.course.learningOutcomes = course.data().learningOutcomes;
          this.course.modules = course.data().modules;
          this.course.desc = course.data().desc;
          this.course.rating = course.data().rating;

          // Kullanıcının ders kayıt bilgilerini al
          this.getCourseRegistration();
        }
      })
      .catch(error => {
        throw `Eğitim bilgileri alınamadı: ${error}`;
      });
  }
});
